/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useState } from "react";

// next
import dynamic from "next/dynamic";
import Link from "next/link";

// MUI
const Collapse = dynamic(() => import("@mui/material/Collapse"), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

// Parts
const DefaultNavbarDropdown = dynamic(() => import('./DefaultNavbarDropdown'), { loading: () => null });

function DefaultNavbarMobile({ open, routes }) {
  const [collapseMenu, setCollapseMenu] = useState("");

  return (
    <>{/* The whole menu */}
      <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
        <MDBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
          {/* Each menu item */}
          {routes.map((route) => (
            <DefaultNavbarDropdown
              key={route.name}
              name={route.name}
              icon={route.icon}
              collapseStatus={route.name === collapseMenu}
              onClick={() => route.collapse !== false && (collapseMenu === route.name ? setCollapseMenu(false) : setCollapseMenu(route.name))}
              href={route.href}
              route={route.route}
              collapse={Boolean(route.collapse)}
            >
              {/* exandable / hidden part */}
              <MDBox sx={{ height: "15rem", maxHeight: "15rem", overflowY: "scroll" }}>
                {route.collapse && route.collapse.map((item) => (
                  <MDBox key={item.name} px={2}>
                    <MDBox width="100%" display="flex" alignItems="center" p={1}>
                      <MDBox display="flex" justifyContent="center" alignItems="center" width="1.5rem" height="1.5rem" borderRadius="md" color="text" mr={1} fontSize="1rem" lineHeight={1}>
                        {item.icon}
                      </MDBox>
                      <MDTypography display="block" variant="button" fontWeight="bold" textTransform="capitalize">
                        {item.name}
                      </MDTypography>
                    </MDBox>
                    {item.collapse.map((el, index) => (
                      <Link key={el.name} href={el.route}>
                        <MDTypography
                          minWidth="11.25rem"
                          display="block"
                          variant="button"
                          color="text"
                          textTransform="capitalize"
                          fontWeight="regular"
                          py={0.625}
                          px={5}
                          mb={index === item.collapse.length - 1 ? 2 : 0}
                          sx={({ palette: { grey, dark }, borders: { borderRadius }, }) => ({ borderRadius: borderRadius.md, cursor: "pointer", transition: "all 300ms linear", "&:hover": { backgroundColor: grey[200], color: dark.main }, })}
                        >
                          {el.name}
                        </MDTypography>
                      </Link>
                    ))}
                  </MDBox>
                ))}
              </MDBox>
            </DefaultNavbarDropdown>
          ),
          )}
        </MDBox>
        {/* CTA Button */}
        <MDBox mb={2}><Link href="/contact"><MDButton variant="contained" color="dark" fullWidth>Contact</MDButton></Link></MDBox>
      </Collapse>
    </>
  );
}

export default DefaultNavbarMobile;
